import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    createTheme,
    Grid,
    Grid2,
    ThemeProvider,
    Typography
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom'; // Use useParams for getting the car id
import {useSwipeable} from 'react-swipeable';
import instagramLogo from "../assets/ig_logo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    backgroundColor,
    buildImageS3Url,
    capitalizeFirstLetter,
    formatNumberWithDots,
    getFuelTypeLabel,
    getModelLabel,
    getPriceLabel,
    getTransmissionLabel
} from "./Home";
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../store/store'; // Import AppDispatch
import {fetchCarDetails, resetCarDetails} from '../store/carDetailsSlice';
import {TypographyStyled} from '../components/CarPostList';
import {Loading} from "../components/Loading";
import BookmarkIcon from "../components/BookmarkIcon";
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import whatsapp_logo from "../assets/whatsapp_logo.png";

// Theme settings
export const theme = createTheme({
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
});

export const padding = '3px';

const NextArrow = (props: any) => {
    const {className, style, onClick} = props;

    // Don't render if it's a mobile device
    if (isMobileDevice()) {
        return null;
    }

    return (
        <div
            className={className}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute", // Position absolutely
                right: "-70px", // Move it further out
                top: "50%", // Center vertically
                transform: "translateY(-50%)",
                zIndex: 1,
                background: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
                borderRadius: "50%", // Circle shape
                width: "60px", // Increase size for better visibility
                height: "60px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow for a nicer look
                cursor: "pointer",
                transition: "background 0.3s ease, transform 0.3s ease", // Smooth background and scaling transition
            }}
            onClick={onClick}
        >
        </div>
    );
};

const PrevArrow = (props: any) => {
    const {className, style, onClick} = props;

    // Don't render if it's a mobile device
    if (isMobileDevice()) {
        return null;
    }

    return (
        <div
            className={className}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute", // Position absolutely
                left: "-70px", // Move it further out
                top: "50%", // Center vertically
                transform: "translateY(-50%)",
                zIndex: 1,
                background: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
                borderRadius: "50%", // Circle shape
                width: "60px", // Increase size for better visibility
                height: "60px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow for a nicer look
                cursor: "pointer",
                transition: "background 0.3s ease, transform 0.3s ease", // Smooth background and scaling transition
            }}
            onClick={onClick}
        >
        </div>
    );
};


const settingsCarousel = {
    dots: true,
    infinite: true,
    speed: 300, // Faster transition speed (lower value for faster sliding)
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true, // Enable arrows for navigation
    nextArrow: <NextArrow/>, // Custom next arrow
    prevArrow: <PrevArrow/>, // Custom previous arrow
};

export function isMobileDevice() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}


const CarDetails: React.FC = () => {
    const {id} = useParams<{ id: string }>(); // Retrieve the car ID from the URL
    const dispatch: AppDispatch = useDispatch();
    const {carDetails, loading, error} = useSelector((state: RootState) => state.carDetails);

    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState(1);  // Track current image index
    const [swipeRegion, setSwipeRegion] = useState<'inside' | 'outside'>('outside');  // Detect where the swipe happens
    const [isFullScreen, setIsFullScreen] = useState(false);  // Full-screen state
    const [fullscreenImageIndex, setFullscreenImageIndex] = useState<number>(0);  // Track full-screen image index
    const [brandAndModel, setBrandAndModel] = useState<string>('');
    const [priceLabel, setPriceLabel] = useState<string>('');
    const sliderRef = useRef<Slider>(null);

    useEffect(() => {
        if (isFullScreen) {
            document.body.style.overflow = 'hidden'; // Lock body scroll
        } else {
            document.body.style.overflow = ''; // Restore body scroll
        }

        return () => {
            document.body.style.overflow = ''; // Clean up on unmount
        };
    }, [isFullScreen]);

    const handleImageClick = (index: number) => {
        setFullscreenImageIndex(index);
        setIsFullScreen(true);  // Trigger full-screen mode
    };

    const handleCloseFullScreen = () => {
        setIsFullScreen(false);
        document.body.style.overflow = ''; // Reset scroll
    };


    const navigate = useNavigate();
    const [multiTouchActive, setMultiTouchActive] = useState(false);

    const handlers = useSwipeable({
        onSwipedLeft: (event) => {
            if (isFullScreen && !multiTouchActive) {
                event.event.stopPropagation();
                setFullscreenImageIndex((prevIndex) => {
                    let index = (prevIndex + 1) % imageUrls.length;
                    goToSlide(index);
                    return index;
                });
            }
        },
        onSwipedRight: (event) => {
            if (isFullScreen && !multiTouchActive) {
                event.event.stopPropagation();
                setFullscreenImageIndex((prevIndex) => {
                    let index = (prevIndex - 1 + imageUrls.length) % imageUrls.length;
                    goToSlide(index);
                    return index;
                });
            }
        },
        onSwipedDown: (event) => {
            if (isFullScreen && !multiTouchActive) {
                event.event.stopPropagation();
                handleCloseFullScreen();
            }
        },
        onSwiping: () => {
            // Cancel any swipe if multi-touch is active
            if (multiTouchActive) return;
        },
        onTouchStartOrOnMouseDown: (event) => {
            const touchEvent = event.event as TouchEvent;
            if (touchEvent.touches && touchEvent.touches.length > 1) {
                setMultiTouchActive(true); // Detect multi-touch
            }
        },
        onTouchEndOrOnMouseUp: () => {
            setMultiTouchActive(false); // Reset after touch ends
        },

        trackMouse: true,
    });
    const [zoomScale, setZoomScale] = useState(1);

    const goToSlide = (index: number) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };


    useEffect(() => {
        const preventZoom = (event: any) => {
            if (!isFullScreen && event.touches.length > 1) {
                event.preventDefault();
            }
        };

        // Attach the event listener to prevent zooming when not in full-screen
        document.addEventListener('touchmove', preventZoom, {passive: false});

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('touchmove', preventZoom);
        };
    }, [isFullScreen]);


// Reset zoom on exiting full-screen mode
    useEffect(() => {
        if (!isFullScreen) {
            setZoomScale(1); // Reset zoom level
        }
    }, [isFullScreen]);

    useEffect(() => {
        if (id) {
            dispatch(fetchCarDetails(id))
                .then(() => {})
                .catch((err) => {
                    console.error('Error fetching car details:', err);
                });
        }

        return () => {
            dispatch(resetCarDetails());
        };
    }, [id, dispatch]);


    useEffect(() => {
        if (carDetails) {
            const urls = [];
            const startPicture = carDetails?.postShortcode?.includes("/") ? 2 : 1;
            const nrOfPictures = carDetails?.numberOfPictures ?? 10;

            for (let i = startPicture; i <= nrOfPictures; i++) {
                urls.push(buildImageS3Url(carDetails, i));
            }
            setImageUrls(urls);

            const brandAndModelValue = capitalizeFirstLetter(carDetails.brand) + " " + getModelLabel(carDetails);
            setBrandAndModel(brandAndModelValue);

            const priceLabelValue = getPriceLabel(carDetails);
            setPriceLabel(priceLabelValue);

            document.title = (carDetails.year ?? "") + " " + brandAndModelValue + " " + priceLabelValue + " - AutoShqip";
        }
    }, [carDetails]);

    const fontSize = '1.1rem';

    const isMobile = isMobileDevice();

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{pl: 0, pr: 0, pt: padding, backgroundColor: backgroundColor}} {...handlers}>
                {
                    loading ? (
                        <Loading/>
                    ) : error ? (
                        <Typography color="error">Error: {error}</Typography>
                    ) : carDetails ? (
                        <Grid container spacing={2} rowSpacing={2}>
                            <Grid item xs={12} md={6} key={carDetails.id} sx={{width: '100%'}}>
                                <Card sx={{position: 'relative', overflow: 'visible'}}>
                                    {/* Carousel for car images */}
                                    <Slider ref={sliderRef} {...settingsCarousel} >
                                        {imageUrls.map((url, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    width: '100%',
                                                    height: '400px',  // Fixed square size for the image
                                                    overflow: 'hidden',
                                                    cursor: 'pointer',
                                                    position: 'relative' // Add relative positioning to parent Box
                                                }}
                                                onClick={() => handleImageClick(index)}  // Open full-screen on click
                                                onMouseEnter={() => setSwipeRegion('inside')}  // Set the region to 'inside' when mouse enters the slider
                                                onMouseLeave={() => setSwipeRegion('outside')}  // Set the region to 'outside' when mouse leaves the slider
                                                onTouchStart={() => setSwipeRegion('inside')}   // For touch devices: Mark region as 'inside'
                                                onTouchEnd={() => setSwipeRegion('outside')}    // For touch devices: Mark region as 'outside' after interaction
                                            >
                                                <Box
                                                    component="img"
                                                    src={url}
                                                    alt={`Image ${index + 1}`}
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',  // Crop the image to fit the box
                                                    }}
                                                />
                                                {/* Add the "S H I T U R" label on top */}
                                                {!loading && carDetails.isSold && <div style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '15%',
                                                    backgroundColor: '#b20000',
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    S H I T U R
                                                </div>}
                                            </Box>
                                        ))}
                                    </Slider>


                                    {isFullScreen && (
                                        <Box {...handlers}
                                             sx={{
                                                 position: 'fixed',
                                                 top: 0,
                                                 left: 0,
                                                 width: '100vw',
                                                 height: '100vh',
                                                 backgroundColor: 'rgba(0, 0, 0, 1)',
                                                 zIndex: 1000,
                                                 display: 'flex',
                                                 justifyContent: 'center',
                                                 alignItems: 'center',
                                                 overflow: 'hidden', // Disable overflow to prevent unintended scroll
                                             }}
                                        >
                                            {/* Full-screen image */}
                                            <Box
                                                component="img"
                                                src={imageUrls[fullscreenImageIndex]}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                    transform: `scale(${zoomScale})`, // Apply zoom scale
                                                    transition: 'transform 0.3s ease', // Smooth reset on exit
                                                }}
                                            />
                                            {/* Close button for desktop */}
                                            <Button
                                                sx={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    right: '20px',
                                                    color: 'white',
                                                    fontSize: '24px',
                                                    zIndex: 1001,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleCloseFullScreen}
                                            >
                                                ✖
                                            </Button>
                                        </Box>
                                    )}


                                    <br/>


                                    <CardContent sx={{backgroundColor: "#f1f1f1"}}>
                                        <Grid2 container justifyContent="space-between">
                                            <Grid2 size={10}>
                                                {/* Main content of the car details */}
                                                <Typography variant="body2" sx={{fontSize: fontSize, pb: '0.8rem'}}>
                                                    <strong>{brandAndModel}</strong>
                                                    <br/>
                                                    {carDetails.trim}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={1}>
                                                <BookmarkIcon carId={carDetails.id}/>
                                            </Grid2>
                                        </Grid2>


                                        <Typography variant="body1"
                                                    sx={{color: 'darkgreen', fontSize: '1.4rem', pb: '0.8rem'}}>
                                            <strong>{priceLabel}</strong>
                                        </Typography>
                                        <Box
                                            sx={{
                                                position: 'relative', // Position at the bottom-left of TextContent
                                                bottom: 0,
                                                left: 0,
                                                width: isMobileDevice() ? '85%' : '55%', // Adjust width as needed
                                                display: 'grid',
                                                gridTemplateColumns: '45fr 55fr',
                                                gridTemplateRows: '45fr 55fr',
                                                gap: '0px',
                                                border: '0px solid lightgrey', // Vertical separator
                                                ml: '0.3rem',
                                                mb: '0.3rem',
                                                padding: '0'
                                            }}
                                        >
                                            {/* Top-left: Year */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0,
                                                fontSize: isMobile ? `clamp(${fontSize}, 1vw, 1rem)` : `clamp(1.1rem, 1vw, 1rem)`,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                ⏳ {carDetails.year}
                                            </TypographyStyled>
                                            {/* Top-right: KM */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0,
                                                fontSize: isMobile ? `clamp(${fontSize}, 1vw, 1rem)` : `clamp(1.1rem, 1vw, 1rem)`,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                👉🏻 {formatNumberWithDots(Math.round(carDetails.km))} KM
                                            </TypographyStyled>
                                            {/* Bottom-left: Transmission */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0.15,
                                                fontSize: isMobile ? `clamp(${fontSize}, 1vw, 1rem)` : `clamp(1.1rem, 1vw, 1rem)`,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {getTransmissionLabel(carDetails.transmission).trim()}
                                            </TypographyStyled>
                                            {/* Bottom-right: Fuel Type */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0,
                                                fontSize: isMobile ? `clamp(${fontSize}, 1vw, 1rem)` : `clamp(1.1rem, 1vw, 1rem)`,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                ⛽ {getFuelTypeLabel(carDetails.fuelTypeId)}
                                            </TypographyStyled>
                                        </Box>
                                        <br/>
                                        <Typography variant="body2" sx={{fontSize: fontSize}}>
                                            ️{formatDateInAlbanian(carDetails.postedTime)}
                                        </Typography>
                                        {/* Phone Number Buttons */}
                                        <br/>
                                        <Box display="flex" flexDirection="column" alignItems="center" sx={{mt: 2}}>
                                            {carDetails.phoneNumbers.split(',')
                                                .filter((i: string) => i.startsWith("06") || i.startsWith("+") || i.startsWith("00"))
                                                .map((phoneNumber: string, index: number) => {
                                                    const formatPhoneNumber = (number: string) => {
                                                        const phoneNumber = parsePhoneNumberFromString(number, 'AL');
                                                        if (phoneNumber) {
                                                            return phoneNumber.formatInternational(); // e.g., +383 48 767 774
                                                        }

                                                        return null;
                                                    };


                                                    const formattedNumber = formatPhoneNumber(phoneNumber);
                                                    const captionLowerCase = carDetails?.caption.toLowerCase();
                                                    const hasWhatsappWord: boolean =
                                                        captionLowerCase.includes("whats")
                                                        || captionLowerCase.includes("whatsapp")
                                                        || captionLowerCase.includes("uatsap")
                                                        || captionLowerCase.includes("vatsap")
                                                        || captionLowerCase.includes("wpp")
                                                        || captionLowerCase.includes("wapp")
                                                        || captionLowerCase.includes("whatsup");

                                                    function phoneNumberOnClick() {
                                                        return () => {
                                                            const trimmedPhoneNumber = formattedNumber?.trim();
                                                            sendAnalyticsEvent("Contact", "ClickPhoneNumber", "ClickPhoneNumber", `${trimmedPhoneNumber}`);
                                                            window.location.href = `tel:${trimmedPhoneNumber}`;
                                                        };
                                                    }

                                                    return (
                                                        <>
                                                            {hasWhatsappWord && <Button
                                                                key={`${index}-whatsapp`}
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: 'darkgreen',
                                                                    color: 'white',
                                                                    width: '70%',
                                                                    mb: 1,
                                                                    '&:hover': {
                                                                        backgroundColor: 'green'
                                                                    },
                                                                    fontSize: fontSize
                                                                }}
                                                                onClick={() => {
                                                                    // Open WhatsApp with the phone number
                                                                    let phoneNumberInternational = phoneNumber.trim();
                                                                    for (const prefix of ['066', '067', '068', '069']) {
                                                                        if (phoneNumberInternational.startsWith(prefix)) {
                                                                            phoneNumberInternational = "+355" + phoneNumberInternational;
                                                                        }
                                                                    }
                                                                    sendAnalyticsEvent("Contact", "ClickWhatsapp", "ClickWhatsapp", phoneNumberInternational);
                                                                    window.location.href = `https://wa.me/${phoneNumberInternational}`;
                                                                }}
                                                            >
                                                                <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 30,
                                                                        mr: 1,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    alt="Whatsapp logo"
                                                                    src={whatsapp_logo}
                                                                />
                                                                {formattedNumber}
                                                            </Button>}

                                                            <Button
                                                                key={`${index}-call`}
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: 'darkred',
                                                                    color: 'white',
                                                                    width: '70%',
                                                                    mb: 1,
                                                                    '&:hover': {
                                                                        backgroundColor: 'red',
                                                                    },
                                                                    fontSize: fontSize
                                                                }}
                                                                onClick={phoneNumberOnClick()}
                                                            >
                                                                {'📱'} {formattedNumber}
                                                            </Button>
                                                        </>
                                                    );
                                                })}
                                        </Box>


                                        {/* Instagram Post Button */}
                                        {carDetails.postShortcode && carDetails.postShortcode.length < 15 && (
                                            <Box display="flex" justifyContent="center" sx={{mt: 2}}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: 'transparent',
                                                        color: 'white',
                                                        width: '80%',
                                                        mb: 1,
                                                        '&:hover': {
                                                            backgroundColor: 'antiquewhite',
                                                        },
                                                        textTransform: "none"
                                                    }}
                                                    onClick={() => {
                                                        const isMobile = isMobileDevice();

                                                        if (isMobile) {
                                                            // Redirect to the Instagram app or open it directly
                                                            if (carDetails.mediaId) {
                                                                window.location.href = `instagram://media?id=${carDetails.mediaId}`;
                                                            } else {
                                                                window.location.href = `https://instagram.com/p/${carDetails.postShortcode}`;
                                                            }
                                                        } else {
                                                            // If on a desktop, go directly to the web URL
                                                            window.open(`https://instagram.com/p/${carDetails.postShortcode}`, '_blank');
                                                        }
                                                    }}
                                                ><Box
                                                    component="img"
                                                    sx={{
                                                        height: 50, // Set the height of the logo
                                                        mr: 2, // Set margin-right to create space after the logo
                                                    }}
                                                    alt="Logo"
                                                    src={instagramLogo} // Use the imported logo image
                                                /><Typography variant="caption" sx={{
                                                    fontSize: fontSize,
                                                    color: 'black',
                                                    fontStyle: "normal"
                                                }}>
                                                    Postimi origjinal @{carDetails.username}
                                                </Typography></Button>
                                            </Box>
                                        )}

                                        <br/>
                                        <Typography variant="body2" sx={{fontSize: fontSize}}>
                                            <strong>Përshkrimi i plotë</strong>
                                        </Typography>
                                        <CardContent>
                                            <Typography variant="body2" sx={{
                                                fontSize: fontSize,
                                                whitespace: "preWrap",
                                                wordWrap: "break-word"
                                            }}>
                                                {decodeHTMLEntities(carDetails.caption)
                                                    .replace('||', "\\n")
                                                    .replace("\\n", "\n")
                                                    .split("\n")
                                                    .map((line: string, index: number) => (
                                                        <div key={index}>
                                                            {line}
                                                        </div>
                                                    ))}
                                            </Typography>
                                        </CardContent>

                                        <Typography variant="body2" sx={{fontSize: fontSize, color: 'darkred', mt: 2}}>
                                            <strong>Kujdes!</strong> Ndonjeherë versioni i makinës mund të mos gjendet
                                            saktë.

                                            <br/><br/>Nëse website ose postimi ka probleme mund të na kontaktoni me anë
                                            të Instagram ose e-mail në fund të faqes.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : null}
            </Container>
        </ThemeProvider>
    );
};

export function getDateAndTimeAgo(inputDate: string) {
    const date = new Date(inputDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    let timeAgo: string;
    const secondsInWeek = 604_800;
    const secondsIn4Weeks = 2_419_200;
    const secondsInYear = 31_536_000;

    if (diffInSeconds < 60) {
        timeAgo = `para ${diffInSeconds} ${diffInSeconds === 1 ? "sekonde" : "sekondash"}`;
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        timeAgo = `para ${minutes} ${minutes === 1 ? "minute" : "minutash"}`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        timeAgo = `para ${hours} ${hours === 1 ? "ore" : "orësh"}`;
    } else if (diffInSeconds < secondsInWeek) {
        const days = Math.round(diffInSeconds / 86400);
        timeAgo = `para ${days} ${days === 1 ? "dite" : "ditësh"}`;
    } else if (diffInSeconds < secondsIn4Weeks) {
        const weeks = Math.round(diffInSeconds / secondsInWeek);
        timeAgo = `para ${weeks} ${weeks === 1 ? "jave" : "javësh"}`;
    } else if (diffInSeconds < secondsInYear) {
        const months = Math.round(diffInSeconds / secondsIn4Weeks);
        timeAgo = `para ${months} ${months === 1 ? "muaji" : "muajsh"}`;
    } else {
        const years = Math.round(diffInSeconds / secondsInYear);
        timeAgo = `para ${years} ${years === 1 ? "viti" : "vitesh"}`;
    }

    return {date, timeAgo};
}

function formatDateInAlbanian(inputDate: string): string {
    let {date, timeAgo} = getDateAndTimeAgo(inputDate);

    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: undefined,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: false
    };

    const formattedDate = date.toLocaleString('sq-AL', options).replace(',', '');

    return `🔥 Postuar ${timeAgo} (${formattedDate})`;
}

const sendAnalyticsEvent = (eventCategory: string, eventAction: string, eventLabel?: string, value?: string) => {
    if (typeof window === "undefined" || window === null || !window.gtag) {
        return;
    }

    window.gtag("event", eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: value,
    });
};

export default CarDetails;

export function decodeHTMLEntities(text: string): string {
    const parser = new DOMParser();
    return parser.parseFromString(text, 'text/html').documentElement.textContent || '';
}