// CarPostList.tsx
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

import React, {useRef, useState} from 'react';
import {Box, Button, Card, CardContent, Grid2, MenuItem, Select, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import clockImage from '../assets/clock.png';
import ig_logo_plain from "../assets/ig_logo_plain.png";

import {
    buildImageS3Url,
    capitalizeFirstLetter,
    CarItem,
    formatNumberWithDots,
    getFuelTypeLabel,
    getModelLabel,
    getPriceLabel,
    getTransmissionLabel,
    TRIM_LETTERS_TO_SLICE,
} from '../pages/Home'; // Adjust paths as needed
import {getDateAndTimeAgo, isMobileDevice} from '../pages/CarDetails';
import {AppDispatch} from "../store/store";
import {useDispatch} from "react-redux";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Loading} from "./Loading"; // Adjust paths as needed
import { TextField } from '@mui/material';
import { containsEmoji } from './FilterForm';
import axios from "axios";
import Alert from "./Alert";
import DeleteCarButton from "./DeleteCarButton";
import { API_BASE_URL } from '../store/carDetailsSlice';


interface CarPostListProps {
    cars: CarItem[] | undefined,
    isLoading: boolean,
    error: any,
    includeIg: boolean,
    showPagination: boolean,
    page: number,
    hasMorePages: boolean,
    padding: string,
    handleCarClick: (id: string) => void,
    title?: string,
    setPageReducer: ActionCreatorWithPayload<number, "filter/setPageIgDisplay" | "filter/setPageNormalDisplay">,
    canEditContent: boolean,
    adminToken: string | undefined
}

export const StyledCard = styled(Card)({
    display: 'flex', // Flex layout for horizontal alignment
    alignItems: 'center',
    fontFamily: 'Inter, sans-serif',
    height: isMobileDevice() ? 140 : 250,
    padding: 0,
    margin: 0,
    // backgroundColor: '#fafafa'
});

export const TextContent = styled(CardContent)({
    position: 'relative',
    height: '100%',
    width: '80%',
    padding: '0.4rem 0.4rem 0 !important',
});

export const TypographyStyled = styled(Typography)({
    lineHeight: '1.1',
    fontFamily: "Inter, sans-serif",
    // fontFamily: "Heebo, Inter, sans-serif",
    // fontFamily: "Oxygen, Inter, sans-serif",
    // fontFamily: "Questrial, Inter, sans-serif",
    // fontFamily: "Lexend Deca, Inter, sans-serif",
    // fontFamily: "Readex Pro, Inter, sans-serif",
});

export const ImageContainer = styled('div')({
    width: '40%', // Set a specific width to keep the image on the left
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
});

export const Image = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

export function isBrandDisabled(brand: string) {
    return !brand || brand.length === 0 || containsEmoji(brand) || brand.includes("---");
}

const CarPostList: React.FC<CarPostListProps> = ({
                                                     cars,
                                                     isLoading,
                                                     error,
                                                     includeIg,
                                                     showPagination,
                                                     page,
                                                     hasMorePages,
                                                     padding,
                                                     handleCarClick,
                                                     title,
                                                     setPageReducer,
                                                     canEditContent,
                                                     adminToken
                                                 }) => {
    const dispatch: AppDispatch = useDispatch();
    const carBrands = useSelector((state: RootState) => state.car.carBrands);
    const carModels = useSelector((state: RootState) => state.car.carModels);

    const [editableFieldsDisplay, setEditableFieldsDisplay] = useState<{ [key: string]: any }>({});
    const [editableFieldsChanges, setEditableFieldsChanges] = useState<{ [key: string]: any }>({});
    const editableFieldsChangesRef = useRef<{ [key: string]: any }>({});
    const [alerts, setAlerts] = useState<{ id: number; message: string; type: 'success' | 'error' }[]>([]);

    const postsContainerRef = useRef<HTMLDivElement | null>(null);

    const addAlert = (message: string, type: 'success' | 'error') => {
        const id = Date.now();
        setAlerts((prev) => [{ id, message, type }, ...prev]);
    };

    const removeAlert = (id: number) => {
        setAlerts((prev) => prev.filter((alert) => alert.id !== id));
    };

    const renderPageNumbers = () => {
        const pages = [];
        const startPage = Math.max(1, page - 1); // Start from the current page - 1
        const endPage = Math.min(page + 1, startPage + 2); // Show up to 3 pages

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Button
                    key={i}
                    sx={{
                        width: '40px',
                        height: '40px', // Square buttons
                        margin: '0 5px',
                        backgroundColor: i === page ? 'black' : 'darkred',
                        color: i === page ? 'white' : 'white',
                        borderRadius: '8px',
                        border: '1px solid #ccc',
                    }}
                    onClick={() => handlePageChange(i)}
                    disabled={i - 1 === page && !hasMorePages} // Disable the current page if no more pages
                >
                    {i}
                </Button>
            );
        }
        return pages;
    };

    const handlePageChange = (newPage: number) => {
        dispatch(setPageReducer(newPage));  // Dispatch action to update page
        sessionStorage.setItem('currentPage', newPage + "");

        // Scroll to the top of the specific container
        if (postsContainerRef.current) {
            postsContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const handleSaveEditedCar = async (carId: string) => {
        try {
            const carData = editableFieldsChanges[carId];
            if (!carData) {
                addAlert('No changes to save for this car', 'error');
                return;
            }

            console.log(carData);

            const response = await axios.patch(`${API_BASE_URL}/cars/${carId}`, carData, {
                headers: {
                    "Content-Type": 'application/json',
                    Authorization: adminToken
                },
            });

            if (response.status !== 200) {
                throw new Error(`Failed to save data for car with ID ${carId}`);
            }

            addAlert('Car data saved successfully!', 'success');
            setEditableFieldsChanges((prev) => {
                const updated = { ...prev };
                delete updated[carId];
                return updated;
            });
        } catch (error: any) {
            addAlert(error.message, 'error');
        }
    };

    const handleDeleteCar = async (postShortcode: string) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/cars/${encodeURIComponent(postShortcode)}`, {
                headers: {
                    "Content-Type": 'application/json',
                    Authorization: adminToken
                },
            });

            if (response.status !== 200) {
                throw new Error(`Failed to delete car with ID ${postShortcode}`);
            }

            addAlert('Car deleted successfully!', 'success');
        } catch (error: any) {
            addAlert(`${error.message}`, 'error');
        }
    };


    return (
        <>
            {alerts.map((alert) => (
                <Alert
                    key={alert.id}
                    message={alert.message}
                    type={alert.type}
                    onClose={() => removeAlert(alert.id)}
                />
            ))}
            {title && (
                <Typography variant="h5" sx={{ fontSize: '1.3rem', textAlign: 'center', mb: 2}}>
                    <strong>{includeIg ? title : "Të gjitha rezultatet"}</strong>
                </Typography>
            )}

            {isLoading ? (
                <Loading/>
            ) : error ? (
                <Typography color="error">Error: {error.message}</Typography>
            ) : (
                <Grid2 container spacing={padding} rowSpacing={padding} ref={postsContainerRef} mb={2}>
                    {cars && cars.map((car) => {
                        const isWebsitePost = car?.postShortcode?.includes("/");
                        const carModel = getModelLabel(car);
                        const carBrand = capitalizeFirstLetter(car.brand);
                        const priceLabel = getPriceLabel(car);
                        let kmValue = formatNumberWithDots(Math.round(car.km));
                        if (kmValue === "")
                            kmValue = "--";

                        let isMobile = isMobileDevice();
                        // Initialize fields if they are not already set in the state
                        if (!editableFieldsDisplay[car.id]) {
                            setEditableFieldsDisplay((prevFields) => ({
                                ...prevFields,
                                [car.id]: {
                                    brand: carBrand,
                                    model: carModel,
                                    trim: car.trim,
                                    price: car.price,
                                    priceLabel: priceLabel,
                                    year: car.year,
                                    km: kmValue,
                                    transmission: getTransmissionLabel(car.transmission).trim(),
                                    fuelType: getFuelTypeLabel(car.fuelTypeId),
                                }
                            }));
                        }

                        const handleFieldChange = (carId: string, field: string, value: string) => {
                            setEditableFieldsDisplay((prevFields) => ({
                                ...prevFields,
                                [carId]: {
                                    ...prevFields[carId],
                                    [field]: value,
                                },
                            }));

                            setEditableFieldsChanges((prevFields) => {
                                const result = {
                                    ...prevFields,
                                    [carId]: {
                                        ...prevFields[carId],
                                        [field]: value,
                                    },
                                };

                                editableFieldsChangesRef.current = result; // Update ref with latest changes
                                return result;
                            });
                        };



                        const EditableField: React.FC<{
                            value: string,
                            onChange: (value: string) => void,
                            fieldType?: 'text' | 'brand' | 'model' // Optional type for the field
                        }> = ({ value, onChange, fieldType = 'text' }) => {
                            const [localValue, setLocalValue] = useState(value);

                            const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                                setLocalValue(e.target.value);
                            };

                            const handleBlur = () => {
                                onChange(localValue);
                            };

                            if (canEditContent && fieldType === 'brand') {
                                return (
                                    <Select
                                        value={value}
                                        onChange={(e) => onChange(e.target.value as string)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        sx={{ padding: 0, fontSize: isMobile ? '0.93rem' : '1.1rem', height: "1.3rem",
                                            '.MuiInputBase-input': {
                                                padding: '0',         // Inner padding for text input
                                                height: '1.1rem'
                                            }
                                    }}
                                    >
                                        {carBrands.map((brand) => (
                                            <MenuItem key={brand} value={brand}
                                                      disabled={isBrandDisabled(brand)}>
                                                {brand}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }

                            if (canEditContent && fieldType === 'model') {
                                const modelsForBrand = carModels[editableFieldsDisplay[car.id]?.brand.toLowerCase()] || [];
                                return (
                                    <>
                                    <Select
                                        value={value}
                                        onChange={(e) => onChange(e.target.value as string)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        sx={{ padding: 0, fontSize: isMobile ? '0.93rem' : '1.1rem', height: "1.3rem",
                                            '.MuiInputBase-input': {
                                                padding: '0',         // Inner padding for text input
                                                height: '1.1rem'
                                            }}}
                                    >
                                        {modelsForBrand.map((model) => (
                                            <MenuItem key={model} value={model}>
                                                {model}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                                );
                            }

                            // Default case for other fields (free text input)
                            return (
                                <TextField
                                    // variant="outlined"
                                    size={"small"}
                                    // value={value}
                                    // onChange={(e) => onChange(e.target.value)}
                                    // onBlur={() => onChange(value)}
                                    value={localValue}
                                    onChange={handleLocalChange}
                                    onBlur={handleBlur}
                                    // sx={{
                                    //     lineHeight: "0.5rem",
                                    //     textAlign: 'left',
                                    //     p: 0,
                                    //     fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                                    //     overflow: 'hidden',
                                    //     whiteSpace: 'nowrap',
                                    //     textOverflow: 'ellipsis',
                                    //     maxHeight: '1rem'
                                    // }}
                                    sx={{
                                        lineHeight: '1',          // Adjust line height
                                        fontSize: '0.2rem',         // Smaller font size
                                        padding: '0',         // Reduce padding for smaller height
                                        '.MuiInputBase-input': {
                                            padding: '0',         // Inner padding for text input
                                            height: '1.1rem'
                                        },
                                        height: '1.1rem',           // Smaller height for input field
                                    }}
                                    // sx={{  fontSize: isMobile ? '0.1rem' : '0.1rem', height: fieldType ? "0.2rem" : "0.5rem"}}
                                />
                            );
                        };


                        let carTrimForDisplay = car.trim || "";
                        for (const text of TRIM_TEXT_TO_REPLACE) {
                            carTrimForDisplay = carTrimForDisplay.replace(text, "");
                        }

                        return (
                            <Grid2 key={car.id} sx={{width: '100%'}} onClick={() => handleCarClick(car.id)}>
                                <StyledCard sx={{position: 'relative'}}>
                                    <ImageContainer sx={{
                                        width: isMobile ? '50%' : '24%',
                                        position: 'relative' // Make the parent a positioning context
                                    }}>
                                        <Image
                                            src={buildImageS3Url(car, isWebsitePost ? 2 : 1)}
                                            alt={`${carBrand} ${carModel}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover', // Ensures image is zoomed and covers the container
                                                position: 'absolute', // Stack on top of each other
                                                top: 0,
                                                left: 0
                                            }}
                                        />
                                        {car.isSold && (
                                            <div style={{
                                                display: 'flex',
                                                position: 'absolute',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '15%',
                                                backgroundColor: '#b20000',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontWeight: 'bold'
                                            }}>
                                                S H I T U R
                                            </div>
                                        )}
                                        {canEditContent &&
                                            <>
                                                <Box
                                                    onClick={(e) => e.stopPropagation()}
                                                    sx={{
                                                        display: 'flex',
                                                        position: 'absolute',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-around',
                                                        bottom: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: isMobile ? '20%' : '7%',
                                                        padding: '0',
                                                        backgroundColor: '#fff',
                                                        boxShadow: '0px -2px 5px rgba(0,0,0,0.1)',
                                                        zIndex: 1000
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: "green",
                                                            padding: 0,
                                                            margin: 0,
                                                            width: '46%',
                                                            height: '100%',
                                                            lineHeight: 1,
                                                            borderRadius: 0
                                                        }}
                                                        onClick={() => handleSaveEditedCar(car.id)} // Save specific car data
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: "grey",
                                                            padding: 0,
                                                            margin: 0,
                                                            width: '46%',
                                                            height: '100%',
                                                            lineHeight: 1,
                                                            borderRadius: 0
                                                        }}
                                                        onClick={() => setEditableFieldsDisplay((prev) => {
                                                            const updated = { ...prev };
                                                            delete updated[car.id];
                                                            return updated;
                                                        })} // Clear changes for this car on cancel
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <DeleteCarButton postShortcode={car.postShortcode} addAlert={addAlert} adminToken={adminToken}/>
                                                </Box>

                                            </>}
                                    </ImageContainer>

                                    {!isWebsitePost && (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (isMobile && car.mediaId) {
                                                    window.location.href = `instagram://media?id=${car.mediaId}`;
                                                } else {
                                                    window.open(`https://instagram.com/p/${car.postShortcode}`, '_blank');
                                                }
                                            }}
                                            style={{
                                                position: 'absolute',
                                                bottom: '0.2rem',
                                                right: '0.4rem',
                                                zIndex: 1
                                            }}
                                        >
                                            <img src={ig_logo_plain} alt="IG Logo"
                                                 style={{ width: '2rem', height: '2rem', filter: 'grayscale(0%)' }}>
                                            </img>
                                        </a>
                                    )}


                                    <TextContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',  // Keep stats at the bottom
                                                pt: '0'  // Optional: Adjust padding if needed
                                            }}
                                        >
                                            { canEditContent &&
                                                <Box onClick={(e) => e.stopPropagation()}>
                                                    <EditableField
                                                        value={editableFieldsDisplay[car.id]?.brand || ''}
                                                        onChange={(value) => handleFieldChange(car.id, 'brand', value)}
                                                        fieldType="brand"
                                                    />
                                                    <EditableField
                                                        value={editableFieldsDisplay[car.id]?.model || ''}
                                                        onChange={(value) => handleFieldChange(car.id, 'model', value)}
                                                        fieldType="model"
                                                    />
                                                    <EditableField
                                                        value={editableFieldsDisplay[car.id]?.trim || ''}
                                                        onChange={(value) => handleFieldChange(car.id, 'trim', value)}
                                                    />
                                                </Box>
                                            }

                                            {!canEditContent &&
                                                <>
                                                    <TypographyStyled variant="body1"
                                                                      sx={{fontSize: isMobile ? '0.93rem' : '1.1rem'}}>
                                                        <strong>{carBrand} {carModel}</strong>
                                                    </TypographyStyled>
                                                    <TypographyStyled variant="body1" sx={{
                                                        fontSize: isMobile ? '0.85rem' : '1rem',
                                                        paddingTop: '0.25rem'
                                                    }}>
                                                        {isMobile && carTrimForDisplay.length > TRIM_LETTERS_TO_SLICE ? carTrimForDisplay.slice(0, TRIM_LETTERS_TO_SLICE) + "..." : carTrimForDisplay}
                                                    </TypographyStyled>
                                                </>
                                            }
                                        </Box>
                                        {canEditContent &&
                                            <Box onClick={(e) => e.stopPropagation()}>
                                                {getPriceCurrencyLabel(car) + "  "}
                                                <EditableField
                                                    value={editableFieldsDisplay[car.id]?.price || ''}
                                                    onChange={(value) => handleFieldChange(car.id, 'price', value)}
                                                />
                                            </Box>
                                        }
                                        {!canEditContent && <TypographyStyled variant="body2"
                                                                             sx={{
                                                                                 color: 'darkgreen',
                                                                                 fontSize: isMobile ? '1.15rem' : '1.3rem',
                                                                                 paddingTop: '0.65rem'
                                                                             }}>
                                            <strong>{priceLabel}</strong>
                                        </TypographyStyled>
                                        }
                                        {canEditContent && <Box onClick={(e) => e.stopPropagation()}>
                                            <TypographyStyled variant="body1" sx={{
                                                fontSize: isMobile ? '0.9rem' : '1rem',
                                                alignItems: "center",
                                                paddingTop: '0rem'
                                            }}>
                                                <img src={clockImage} alt="IG Logo"
                                                     style={{width: '0.8rem', height: '0.8rem'}}/>
                                                <span> {capitalizeFirstLetter(getDateAndTimeAgo(car.postedTime).timeAgo)}</span>
                                            </TypographyStyled>
                                        </Box>
                                        }
                                        {!canEditContent && <TypographyStyled variant="body1" sx={{
                                            fontSize: isMobile ? '0.9rem' : '1rem',
                                            alignItems: "center",
                                            paddingTop: '0.65rem'
                                        }}>
                                            <img src={clockImage} alt="IG Logo"
                                                 style={{width: '0.8rem', height: '0.8rem'}}/>
                                            <span> {capitalizeFirstLetter(getDateAndTimeAgo(car.postedTime).timeAgo)}</span>
                                        </TypographyStyled>
                                        }

                                        {canEditContent &&
                                            <Box
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                sx={{
                                                    position: 'absolute', // Position at the bottom-left of TextContent
                                                    bottom: 0,
                                                    left: 0,
                                                    width: isMobile ? '85%' : '30%', // Adjust width as needed
                                                    display: 'grid',
                                                    gridTemplateColumns: '9fr 11fr',
                                                    gridTemplateRows: '9fr 11fr',
                                                    gap: '0px',
                                                    border: '0px solid lightgrey', // Vertical separator
                                                    padding: '0',
                                                    marginLeft: '0.3rem',
                                                    maxHeight: "3rem"
                                                }}
                                            >
                                                <EditableField
                                                    value={editableFieldsDisplay[car.id]?.year || ''}
                                                    onChange={(value) => handleFieldChange(car.id, 'year', value)}
                                                />
                                                <EditableField
                                                    value={editableFieldsDisplay[car.id]?.km || ''}
                                                    onChange={(value) => handleFieldChange(car.id, 'km', value)}
                                                />
                                                <EditableField
                                                    value={editableFieldsDisplay[car.id]?.transmission || ''}
                                                    onChange={(value) => handleFieldChange(car.id, 'transmission', value)}
                                                />
                                                <EditableField
                                                    value={editableFieldsDisplay[car.id]?.fuelType || ''}
                                                    onChange={(value) => handleFieldChange(car.id, 'fuelType', value)}
                                                />
                                            </Box>
                                        }


                                        {!canEditContent && <Box
                                            sx={{
                                                position: 'absolute', // Position at the bottom-left of TextContent
                                                bottom: 0,
                                                left: 0,
                                                width: isMobile ? '85%' : '30%', // Adjust width as needed
                                                display: 'grid',
                                                gridTemplateColumns: '45fr 55fr',
                                                gridTemplateRows: '45fr 55fr',
                                                gap: '0px',
                                                border: '0px solid lightgrey', // Vertical separator
                                                ml: '0.3rem',
                                                mb: '0.3rem',
                                                padding: '0'
                                            }}
                                        >
                                            {/* Top-left: Year */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0,
                                                fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {car.year}
                                            </TypographyStyled>

                                            {/* Top-right: KM */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0,
                                                fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {kmValue} KM
                                            </TypographyStyled>

                                            {/* Bottom-left: Transmission */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0.15,
                                                fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {getTransmissionLabel(car.transmission).trim()}
                                            </TypographyStyled>

                                            {/* Bottom-right: Fuel Type */}
                                            <TypographyStyled variant="body2" sx={{
                                                textAlign: 'left',
                                                p: 0,
                                                fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {getFuelTypeLabel(car.fuelTypeId)}
                                            </TypographyStyled>

                                        </Box>
                                        }

                                    </TextContent>
                                </StyledCard>
                            </Grid2>
                        );
                    })}
                </Grid2>
            )}

            {showPagination && (
                <Box display="flex" justifyContent="center" sx={{mt: 2, mb: 1}}>
                    <Button
                        sx={{
                            width: '40px',
                            height: '40px',
                            margin: '0 5px',
                            backgroundColor: 'darkred',
                            color: page === 1 ? 'white' : 'white',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                        }}
                        onClick={() => handlePageChange(1)}
                    >
                        {"<<"}
                    </Button>
                    {renderPageNumbers()}
                    <Button
                        disabled={!hasMorePages}
                        sx={{
                            width: '40px',
                            height: '40px',
                            margin: '0 5px',
                            backgroundColor: 'darkred',
                            color: hasMorePages ? 'white' : 'grey',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                        }}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {">"}
                    </Button>
                </Box>
            )}

            {includeIg && <Typography variant="body2" sx={{fontSize: '1rem', color: 'black', m: 2}}>
                Edhe nëse nuk shfaqen fillimisht, të gjitha postimet nga Instagrami të <strong>+150 faqeve</strong> që ne ndjekim do të shfaqen këtu në Auto Shqip me kalimin e orëve.
            </Typography>}




        </>
    );
};

export function getPriceCurrencyLabel(car: CarItem) {
    if (car.price && Math.round(parseInt(car.price)) > 300000) {
        // It is probably LEK.
        return "LEK";
    }

    return !car.price ? "€" : car.priceCurrency;
}

export const TRIM_TEXT_TO_REPLACE = [
    "4MATIC+",
    "4MATIC",
    "BlueEFFICIENCY",
    "BlueTEC",
    "Mild Hybrid",
    "Hybrid",
    "4G-TRONIC",
    "5G-TRONIC",
    "6G-TRONIC",
    "7G-TRONIC",
    "8G-TRONIC",
    "9G-TRONIC",
    "10G-TRONIC",
    "G-TRONIC",
    "SPEEDSHIFT MCT",
    "SPEEDSHIFT",
    "S-Modell",
    "S-Model",
    "V6",
    "PLUS",
    "DCT",
    "TCT",
    "DPF",
    "CGI",
    "PDK",
    "DSG",
    "MCT",
    "Grand Edition",
    "Automatic",
    "Manual",
    "Edition",
    "Performance Package",
    "Start \u0026 Stop",
    "clean diesel",
    "quattro",
    "Tiptronic",
    "tiptronic",
    "multitronic",
    "ultra",
    "4-speed",
    "5-speed",
    "6-speed",
    "7-speed",
    "4-seats",
    "5-seats",
    "6-seats",
    "7-seats",
    "4 seats",
    "5 seats",
    "6 seats",
    "7 seats",
    "5+2 Seating",
    "7 Seating",
    "7 Seat",
    "6 Seating",
    "6 Seat",
    "5 Seating",
    "5 Seat",
];


export default CarPostList;
