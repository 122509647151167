import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@mui/material'; // Import Collapse and IconButton
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import icons for expand
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import {useNavigate} from "react-router-dom"; // Import RootState type
import ClearIcon from '@mui/icons-material/Clear';
import {isBrandDisabled} from './CarPostList';
import {styled} from "@mui/material/styles";
import SearchableDropdown from "./SearchableDropdown";


// Fuel type options
const fuelTypes = [
    { label: 'Naftë', value: '1' },
    { label: 'Benzinë', value: '2' },
    { label: 'Benzinë + Gas', value: '3' },
    // { label: 'Gas', value: '4' },
    // { label: 'Hidrogjen', value: '5' } ,
    { label: 'Elektrike', value: '6' },
];

const sortingOptions = [
    { label: 'Koha e postimit (më e reja)', value: 'postedTime&ascending=true' },
    { label: 'Koha e postimit (më e vjetra)', value: 'postedTime' },
    { label: 'Çmimi (më i lirë)', value: 'price' },
    { label: 'Çmimi (më i shtrenjtë)', value: 'price&ascending=true' },
    { label: 'KM (më i ulët)', value: 'km' },
    { label: 'KM (më i lartë)', value: 'km&ascending=true' },
    { label: 'Viti i prodhimit (më i ri)', value: 'year&ascending=true' },
    { label: 'Viti i prodhimit (më i vjetër)', value: 'year' },
];

const sourceOptions = [
    { label: 'Te gjitha', value: '' },
    { label: 'Instagram', value: 'IG' },
    { label: 'MerrJep', value: 'Website' }
];

const StyledPaper = styled(Paper)({
    position: 'absolute',
    width: '100%',
    maxHeight: '19rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    touchAction: 'pan-y',
    marginTop: 4,
    zIndex: 10,
});

interface FilterFormProps {
    onFilter: (filters: any) => void;
    initialFilters: any; // New prop to accept initial filter values
    moveToSearch: boolean;
}

const FilterForm: React.FC<FilterFormProps> = ({ onFilter, initialFilters, moveToSearch }) => {
    const [selectedBrand, setSelectedBrand] = useState<keyof typeof carModels | ''>(initialFilters?.brand || '');
    const [selectedModel, setSelectedModel] = useState(initialFilters?.model || '');
    const [minYear, setMinYear] = useState(initialFilters?.minYear || '');
    const [maxYear, setMaxYear] = useState(initialFilters?.maxYear || '');
    const [minPrice, setMinPrice] = useState(initialFilters?.minPrice || '');
    const [maxPrice, setMaxPrice] = useState(initialFilters?.maxPrice || '');
    const [minKm, setMinKm] = useState(initialFilters?.minKm || '');
    const [maxKm, setMaxKm] = useState(initialFilters?.maxKm || '');
    const [selectedFuelTypes, setSelectedFuelTypes] = useState(initialFilters?.fuelTypes || []);
    const [transmission, setTransmission] = useState(initialFilters?.transmission || 'any');
    const [sort, setSort] = useState(initialFilters?.sort || '');
    const [sourceName, setSourceName] = useState(initialFilters?.sourceName || '');
    const [keywords, setKeywords] = useState(initialFilters?.keywords || '');
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false); // New state for toggling advanced search
    const carBrands = useSelector((state: RootState) => state.car.carBrands);
    const carModels = useSelector((state: RootState) => state.car.carModels);
    const navigate = useNavigate();
    const [searchTermBrands, setSearchTermBrands] = useState('');
    const [searchTermModels, setSearchTermModels] = useState('');
    const [filteredCarBrands, setFilteredCarBrands] = useState(carBrands);
    const [filteredCarModels, setFilteredCarModels] = useState<string[]>([]);

    useEffect(() => {
        setFilteredCarBrands(
            carBrands.filter((brand) =>
                searchTermBrands === '' || (!isBrandDisabled(brand) && brand.toLowerCase().includes(searchTermBrands.toLowerCase()))
            )
        );
    }, [searchTermBrands, carBrands]);

    useEffect(() => {
        setFilteredCarModels(
            (carModels[selectedBrand.toLowerCase()] || []).filter((model) =>
                searchTermModels === '' || model.toLowerCase().includes(searchTermModels.toLowerCase())
            )
        );
    }, [searchTermModels, selectedBrand, carModels]);


// Add this useEffect to FilterForm to reset filters when initialFilters changes
    useEffect(() => {
        setSelectedBrand(initialFilters?.brand || '');
        setSelectedModel(initialFilters?.model || '');
        setMinYear(initialFilters?.minYear || '');
        setMaxYear(initialFilters?.maxYear || '');
        setMinPrice(initialFilters?.minPrice || '');
        setMaxPrice(initialFilters?.maxPrice || '');
        setMinKm(initialFilters?.minKm || '');
        setMaxKm(initialFilters?.maxKm || '');
        setSelectedFuelTypes(initialFilters?.fuelTypes || []);
        setTransmission(initialFilters?.transmission || 'any');
        setSort(initialFilters?.sort || '');
        setSourceName(initialFilters?.sourceName || '');
        setKeywords(initialFilters?.keywords || '');
    }, [initialFilters]);

    // Handle multi-select fuel type
    const handleFuelTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSelectedFuelTypes((prev: string[]) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    // Handle form submission
    const handleSubmit = () => {
        const filters = {
            brand: selectedBrand,
            model: selectedModel,
            minYear,
            maxYear,
            minPrice,
            maxPrice,
            minKm,
            maxKm,
            fuelTypes: selectedFuelTypes,
            transmission,
            sourceName,
            keywords,
            sort,
        };
        onFilter(filters);

        if (moveToSearch) {
            navigate('/search');
        }
    };

    return (
        <>
            <div>
                <Grid container spacing={2}>
                    {/* Brand and Min Year */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            {/* Brand Selection */}
                            <Grid item xs={6}>
                                <SearchableDropdown
                                    label="Marka"
                                    disabled={false}
                                    options={filteredCarBrands}
                                    selectedValue={selectedBrand}
                                    onSelect={(brand) => {
                                        setSelectedBrand(brand);
                                        setSelectedModel(''); // reset model if needed
                                    }}
                                    onClear={() => {
                                        setSelectedBrand('');
                                        setSearchTermBrands('');
                                        setSelectedModel('');
                                        setSearchTermModels('');
                                        setFilteredCarModels([]);
                                    }}
                                />
                            </Grid>

                            {/* Model Selection */}
                            <Grid item xs={6}>
                                <SearchableDropdown
                                    label="Modeli"
                                    disabled={!selectedBrand}
                                    options={filteredCarModels}
                                    selectedValue={selectedModel}
                                    onSelect={(model) => setSelectedModel(model)}
                                    onClear={() => {
                                        setSelectedModel('');
                                        setSearchTermModels('');
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Min Year and Max Year */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            {/* Min Year */}
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Viti (min)"
                                    type="number"
                                    value={minYear}
                                    onChange={(e) => setMinYear(e.target.value)}
                                    size="small"
                                    slotProps={{
                                        input: {
                                            endAdornment: minYear && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setMinYear('');
                                                    }}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            ),
                                        },
                                        select: {
                                            IconComponent: minYear ? () => null : undefined,
                                        },
                                    }}
                                    sx={{
                                        backgroundColor: 'white',
                                        fontFamily: "Inter, sans-serif"
                                    }}
                                />
                            </Grid>
                            {/* Max Year */}
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Viti (max)"
                                    type="number"
                                    value={maxYear}
                                    onChange={(e) => setMaxYear(e.target.value)}
                                    size="small"
                                    slotProps={{
                                        input: {
                                            endAdornment: maxYear && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setMaxYear('');
                                                    }}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            ),
                                        },
                                        select: {
                                            IconComponent: maxYear ? () => null : undefined,
                                        },
                                    }}
                                    sx={{
                                        backgroundColor: 'white',
                                        fontFamily: "Inter, sans-serif"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Toggle Button for Advanced Search */}
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                            endIcon={showAdvancedSearch ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            sx={{
                                backgroundColor: 'white',
                                color: 'black',
                                fontFamily: "Inter, sans-serif",
                                borderColor: 'grey.500', // Sets the border to grey
                                mt: 1 // Reduce the margin-top to bring the buttons closer together
                            }}
                        >
                            {showAdvancedSearch ? "Mbylle" : "Kërkim i Avancuar"}
                        </Button>
                    </Grid>


                    {/* Advanced Search Fields in Collapse */}
                    <Grid item xs={12}>
                        <Collapse in={showAdvancedSearch}>
                            <Grid container spacing={2}>
                                {/* Min Price and Max Price */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2}>
                                        {/* Min Price */}
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Çmimi (min)"
                                                placeholder="Euro"
                                                type="number"
                                                value={minPrice}
                                                onChange={(e) => setMinPrice(e.target.value)}
                                                size="small"
                                                slotProps={{
                                                    input: {
                                                        endAdornment: minPrice && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setMinPrice('');
                                                                }}
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                    },
                                                    select: {
                                                        IconComponent: minPrice ? () => null : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    fontFamily: "Inter, sans-serif"
                                                }}
                                            />
                                        </Grid>

                                        {/* Max Price */}
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Çmimi (max)"
                                                placeholder="Euro"
                                                type="number"
                                                value={maxPrice}
                                                onChange={(e) => setMaxPrice(e.target.value)}
                                                size="small"
                                                slotProps={{
                                                    input: {
                                                        endAdornment: maxPrice && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setMaxPrice('');
                                                                }}
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                    },
                                                    select: {
                                                        IconComponent: maxPrice ? () => null : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    fontFamily: "Inter, sans-serif"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Min Km and Max Km */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2}>
                                        {/* Min Km */}
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="KM (min)"
                                                type="number"
                                                value={minKm}
                                                onChange={(e) => setMinKm(e.target.value)}
                                                size="small"
                                                slotProps={{
                                                    input: {
                                                        endAdornment: minKm && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setMinKm('');
                                                                }}
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                    },
                                                    select: {
                                                        IconComponent: minKm ? () => null : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    fontFamily: "Inter, sans-serif"
                                                }}
                                            />
                                        </Grid>

                                        {/* Max Km */}
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="KM (max)"
                                                type="number"
                                                value={maxKm}
                                                onChange={(e) => setMaxKm(e.target.value)}
                                                size="small"
                                                slotProps={{
                                                    input: {
                                                        endAdornment: maxKm && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setMaxKm('');
                                                                }}
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                    },
                                                    select: {
                                                        IconComponent: maxKm ? () => null : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    fontFamily: "Inter, sans-serif"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Transmission */}
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Kambio"
                                        value={transmission}
                                        onChange={(e) => setTransmission(e.target.value)}
                                        size="small"
                                        sx={{
                                            backgroundColor: 'white',
                                            fontFamily: "Inter, sans-serif"
                                        }}
                                    >
                                        <MenuItem value="any">Të gjitha</MenuItem>
                                        <MenuItem value="MANUAL">Manual</MenuItem>
                                        <MenuItem value="AUTOMATIC">Automatik</MenuItem>
                                    </TextField>
                                </Grid>

                                {/* Fuel Type (Multi-option checkbox) */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" gutterBottom>
                                        Karburanti
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap">
                                        {fuelTypes.map((fuel) => (
                                            <FormControlLabel
                                                key={fuel.value}
                                                control={
                                                    <Checkbox
                                                        checked={selectedFuelTypes.includes(fuel.value)}
                                                        onChange={handleFuelTypeChange}
                                                        value={fuel.value}
                                                    />
                                                }
                                                label={fuel.label}
                                            />
                                        ))}
                                    </Box>
                                </Grid>

                                {/* Keywords */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Fjala kyçe p.sh. 220, panoram, AMG, keyless"
                                        value={keywords}
                                        onChange={(e) => setKeywords(e.target.value)}
                                        size="small"
                                        placeholder="ngrohje, quattro, 4matic, start/stop..."
                                        slotProps={{
                                            input: {
                                                endAdornment: keywords && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setKeywords('');
                                                        }}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                ),
                                            },
                                            select: {
                                                IconComponent: keywords ? () => null : undefined,
                                            },
                                        }}
                                        sx={{
                                            backgroundColor: 'white',
                                            fontFamily: "Inter, sans-serif"
                                        }}
                                    />
                                </Grid>


                                {/* Source */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Burimi"
                                        value={sourceName}
                                        onChange={(e) => setSourceName(e.target.value)}
                                        size="small"
                                        sx={{
                                            backgroundColor: 'white',
                                            fontFamily: "Inter, sans-serif"
                                        }}
                                    >
                                        {sourceOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                {/* Sorting */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Rendit sipas"
                                        value={sort}
                                        onChange={(e) => setSort(e.target.value)}
                                        size="small"
                                        sx={{
                                            backgroundColor: 'white',
                                            fontFamily: "Inter, sans-serif"
                                        }}
                                    >
                                        {sortingOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                            </Grid>
                        </Collapse>
                    </Grid>

                    {/* Search Button */}
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{backgroundColor: 'darkred', color: 'white', mt: 0}}
                            onClick={handleSubmit}
                        >
                            🔍 Kërko
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button></Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export function containsEmoji(str: string): boolean {
    const emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;
    return emojiRegex.test(str);
}

export default FilterForm;
