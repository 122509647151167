import React from 'react';
import { Box, Button, TextField, Grid } from '@mui/material';

// Import the image if it's in your local assets folder
// You can replace 'image.png' with the path to your uploaded image or another URL if the image is hosted elsewhere
import image1 from '../assets/car_background.jpg'; // Change this to your image path
import image2 from '../assets/mustang.jpg'; // Change this to your image path
import image3 from '../assets/image3.jpg'; // Change this to your image path
import image4 from '../assets/image4.jpg'; // Change this to your image path
const randomImage = getRandomElement([image1, image2, image3, image4]);


const HeroSection: React.FC = () => {
    return (
        <Box
            sx={{
                height: '8.5rem', // Set the height for the section
                backgroundImage: `url(${randomImage})`, // Set background image
                backgroundSize: 'cover', // Ensure the image covers the whole area
                backgroundPosition: 'center', // Center the background image
                display: 'flex', // Use flexbox to center the content
                alignItems: 'center', // Center content vertically
                justifyContent: 'center', // Center content horizontally
                color: 'white', // Text color to stand out against the background
                position: 'relative', // Optional: For further positioning or overlapping
                textAlign: 'center', // Center the text inside the box
            }}
        >
            {/* This is the overlay with text and the form */}
            <Box sx={{ maxWidth: '800px', padding: '20px' }}>
                <h1 style={{ fontSize: '1.3rem', fontFamily: 'Magra, Inter, sans-serif', marginBottom: '20px',  textShadow: '0px 0px 2px black', }}>
                    Të gjitha makinat nga Instagrami dhe MerrJep i gjeni këtu.
                </h1>
            </Box>
        </Box>
    );
};

function getRandomElement(arr: any[]): any {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}

export default HeroSection;
